import {onInput} from '../onfocus'

onInput('.js-login-field', function (event) {
  const loginElement = event.target as HTMLInputElement
  const form = loginElement.closest<HTMLElement>('form')
  if (!form) return

  const passwordInput = form.querySelector<HTMLInputElement>('.js-password-field')
  const signInButton = form.querySelector<HTMLInputElement>('.js-sign-in-button')
  if (!passwordInput || !signInButton) return
  const loginInput = loginElement.value
  const params = new URLSearchParams(window.location.search)

  const valid_params = params.has('client_id') || params.has('return_to')
  const invalidUnderscoreLogin = ['pj_nitin', 'up_the_irons']

  if (
    valid_params &&
    loginInput.includes('_') &&
    !loginInput.includes('_admin') &&
    !loginInput.includes('@') &&
    !invalidUnderscoreLogin.includes(loginInput)
  ) {
    passwordInput.setAttribute('disabled', 'true')
    signInButton.value = signInButton.getAttribute('data-sso-label') || ' '
  } else {
    passwordInput.removeAttribute('disabled')
    signInButton.value = signInButton.getAttribute('data-signin-label') || ' '
  }
})
